import httpHelper from "@/helpers/httpHelper";

const pointShopService = {
    async get(data) {
        return await httpHelper.get("/WebApi/PointShop/Get", data);
    },
    async buy(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/PointShop/Buy", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
};

export default pointShopService;